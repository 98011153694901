@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.leaflet-tooltip.my-labels {
  background-color: transparent;
  border: transparent;
  box-shadow: none;
}

.leaflet-control-attribution {
  display: none;
}

@media (max-width: 500px) {
  .MuiBox-root {
    padding: 10px 0 !important;
  }
}
