.libraryCard{
    width: 230;
    display: grid;

    .downloadIcon{
        font-size: 18px;
    }

    .cardHeader{
        .MuiCardHeader-title{
            font-size: 16px;
        }
        
        .MuiCardHeader-subheader{
            font-size: 12px;
            font-weight: bold;
        }
    }

    .cardMedia{
        margin-bottom: 7px;
        height: 70px;
        width: 70px;
        justify-self: center;
    }

    .cardContent{
        display: grid;
        gap : 3px;

        .description{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .more{
                font-weight: bold;
                font-size: 14px;
                color: rgb(31, 112, 192);
                text-decoration: none;
            }
        }

        .custom-badge-container{
            display: flex;
            justify-content: end;
            margin-top: 10px;

            .custom-badge{
                padding: 3px 5px;
                background-color: rgba(51, 153, 255, 0.24);
                color: rgba(51, 153, 255);
                font-weight: bold;
                border-radius: 7px;
            }
        }
    }

    .cardActions{

        .date-and-doc-size{
            width: 100%;
            display: flex;
            justify-content: space-between;
            color: #707070;
        }

        .edit-button{
            width: 100%;
            background-color: rgba(51, 153, 255, 0.24);
            color: rgba(51, 153, 255);
            padding: 2px;
            z-index: 3;
        }
    }
}